import React, { useCallback, useEffect, useState } from 'react';
import useChat from '../hooks/useChat';
import ButtonExampleMessage from './ButtonExampleMessage';
import { ExampleItem } from '../@types/bot';
import ButtonIcon from './ButtonIcon';
import { produce } from 'immer';
import { PiChat, PiFolderFill, PiTriangleFill, PiX } from 'react-icons/pi';

type Props = {
  onSendExample: (e: React.MouseEvent) => void;
  examples?: ExampleItem[];
  disabled?: boolean;
};

const ExampleButtons: React.FC<Props> = (props) => {
  const { messages } = useChat();
  const [isClose, setClose] = useState<boolean>(false);
  const [isCloseFromUser, setCloseFromUser] = useState<boolean>(false);
  const [level, setLevel] = useState<number>(1);
  const [parents, setParents] = useState<string[]>([]);
  const [displayExamples, setDisplayExamples] = useState<ExampleItem[]>([]);

  const hasChildren = useCallback((checExample: ExampleItem) => {
    if (!props.examples) { return false }
    for (const example of props.examples) {
      if (example.parent == checExample.id) {
        return true;
      }
    }
    return false;
  }, [props]);

  useEffect(() => {
    if (isCloseFromUser) { return; }
    if (messages.length < 2 && isClose) { setClose(false); }
    if (messages.length >= 2 && !isClose) { setClose(true); }
  }, [isClose, messages, isCloseFromUser])

  const openExamples = useCallback(() => {
    setClose(false);
    setCloseFromUser(true);
  }, []);

  const closeExamples = useCallback(() => {
    setClose(true);
    setCloseFromUser(true);
  }, []);

  const backExamples = useCallback(() => {
    setLevel(level - 1);
    setParents(produce(parents, draft => {
      draft.pop();
    }));
  }, [parents, level]);

  const onClickExample = useCallback((selectedExample: ExampleItem, e: React.MouseEvent) => {
    if (!props.examples) { return; }
    if (hasChildren(selectedExample)) {
      // 子がいる場合はlevelとparentを設定
      setParents(produce(parents, draft => {
        draft.push(selectedExample.id)
      }));
      setLevel(selectedExample.level + 1)
    } else {
      // 子がいない場合は呼出
      props.onSendExample(e);
    }
  }, [props, parents, hasChildren])

  useEffect(() => {
    if (!props.examples) { return }
    const _examples: ExampleItem[] = []
    for (const example of props.examples) {
      if (example.level == level && example.parent == parents[parents.length - 1]) {
        // 現在のレベル・親に合致するもののみを表示
        _examples.push(example)
      }
    }
    setDisplayExamples(_examples)
  }, [props.examples, level, parents])

  return (
    <div
      className="relative w-11/12 md:w-10/12 lg:w-4/6 xl:w-3/6">
      {!isClose && props.examples && (
        <div className='flex flex-wrap justify-center w-full gap-2 mb-2 rounded-xl shadow-xl bg-aws-squid-ink/5'>
          {level != 1 && parents && (
            <ButtonIcon className="absolute top-0 left-0 m-1 p-0" onClick={backExamples}>
              <PiTriangleFill size='1rem' style={{ transform: 'rotate(-90deg)' }} />
            </ButtonIcon>
          )}
          {displayExamples.map((example, idx) => (
            <ButtonExampleMessage
              key={idx}
              className="bg-aws-paper p-2 w-5/12 whitespace-normal break-all"
              disabled={props.disabled}
              outlined
              icon={hasChildren(example) ? <PiFolderFill /> : <PiChat />}
              onClick={onClickExample.bind(this, example)}>
              {example.example}
            </ButtonExampleMessage >
          ))}
          {displayExamples.length > 0 && (
            <ButtonIcon className="absolute top-0 right-0 mx-1 p-0 " onClick={closeExamples}>
              <PiX />
            </ButtonIcon>
          )}
        </div>
      )}
      {isClose && displayExamples.length > 0 && props.examples && (
        <div className='flex flex-wrap justify-center w-full gap-2'>
          <ButtonIcon onClick={openExamples}>
            {<PiTriangleFill />}
          </ButtonIcon>
        </div>)}
    </div>
  );
};

export default ExampleButtons;
