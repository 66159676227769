import React, { useEffect } from 'react';

const FederateAuthenticatedPage: React.FC = () => {

    useEffect(() => {
        setTimeout(() => { window.close(); }, 1000);
    })

    return (<></>);
};

export default FederateAuthenticatedPage;
