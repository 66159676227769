import React, { useMemo } from 'react';
import { BaseProps } from '../@types/common';
import { useFaceRecognition } from '../hooks/useFaceRecognition';
import { FaSearch } from "react-icons/fa"
import { FaRegFaceFlushed, FaRegFaceFrown, FaRegFaceGrimace, FaRegFaceGrinBeamSweat, FaRegFaceLaughSquint, FaRegFaceMeh, FaRegFaceSadTear } from "react-icons/fa6"

type Props = BaseProps & {
  size?: number
};

const FaceExpressionsIndicator: React.FC<Props> = (props) => {
  const { isReady, detectedFaceExpressions } = useFaceRecognition();
  const indicatorIcon = useMemo(() => {
    if (!isReady) {
      return <></>
    }
    const expression = detectedFaceExpressions?.asSortedArray()[0].expression;
    switch (expression) {
      case "neutral":
        // 普通
        return <FaRegFaceMeh size={props.size} />;
      case "happy":
        // うれしい
        return <FaRegFaceLaughSquint size={props.size} />;
      case "sad":
        // 悲しい
        return <FaRegFaceSadTear size={props.size} />;
      case "angry":
        // 怒っている
        return <FaRegFaceFrown size={props.size} />;
      case "fearful":
        // 怖い
        return <FaRegFaceGrimace size={props.size} />;
      case "disgusted":
        // あきれている
        return <FaRegFaceGrinBeamSweat size={props.size} />;
      case "surprised":
        // 驚き
        return <FaRegFaceFlushed size={props.size} />;
      default:
        // 検出不可
        return <FaSearch size={props.size} />;
    }

  }, [detectedFaceExpressions, isReady, props.size])

  return indicatorIcon;
};

export default FaceExpressionsIndicator;