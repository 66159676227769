import { produce } from 'immer';
import { CreateLinkRequest, EzAuthLink } from '../@types/ez-auth';
import useEzAuthApi from './useEzAuthApi';
import { create } from 'zustand';

const useEzAuthState = create<{
  isCreating: boolean;
  setIsCreating: (b: boolean) => void;
}>((set) => {
  return {
    isCreating: false,
    setIsCreating: (s) => {
      set(() => {
        return {
          isCreating: s,
        };
      });
    },
  };
});

const useEzAuth = (shouldAutoRefreshMyLinks?: boolean, shouldAutoRefreshAllUsersLinks?: boolean) => {
  const api = useEzAuthApi();
  const { isCreating, setIsCreating } = useEzAuthState();

  const { createLink: createLinkApi } = useEzAuthApi();

  const { data: myLinks, mutate: mutateMyLinks } = api.links(
    {
      kind: 'private',
    },
    shouldAutoRefreshMyLinks ? () => 5000 : undefined
  );
  const composeUrl = (link: EzAuthLink) => {
    return `${window.location.origin}/ez_auth/${link.userId}/${link.id}`
  }
  const createLink = async (params: CreateLinkRequest) => {
    setIsCreating(true);
    const response = await createLinkApi(params)
    mutateMyLinks(
      produce(myLinks, (draft) => {
        draft?.[0]?.unshift(response.data);
      }),
      {
        revalidate: false,
      })
    setIsCreating(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: allUsersLinks } = api.links(
    {
      kind: 'mixed',
    },
    shouldAutoRefreshAllUsersLinks ? () => 5000 : undefined
  );
  return {
    isCreating,
    myLinks,
    allUsersLinks,
    composeUrl,
    createLink,
  }
};

export default useEzAuth;
