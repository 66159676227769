import React from 'react';
import AuthPopup from '../components/FederatedSignIn';
import { useLocation } from 'react-router-dom';
// import { Amplify } from 'aws-amplify';

const FederateAuthenticatePage: React.FC = () => {

    const queryParams = new URLSearchParams(useLocation().search);
    const provider = queryParams.get('provider');
    if (!provider){
        return <>不正な設定です。</>
    }

    return (
        <div className="flex h-screen flex-col items-center justify-center">
            <div className="flex text-5xl font-bold">
                <AuthPopup
                    customProvider={provider}
                ></AuthPopup>
            </div>
        </div>
    );
};

export default FederateAuthenticatePage;
