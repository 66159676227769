import React, { useState } from 'react';
import Button from '../components/Button';
import { QRCodeSVG } from 'qrcode.react';
import { t } from 'i18next';
import useEzAuth from '../hooks/useEzAuth';
import ListItemLink from '../components/ListItemLink';
import ModalDialog from '../components/ModalDialog';
import { EzAuthLink } from '../@types/ez-auth';
import { formatDatetime } from '../utils/DateUtils';
import ListItemCollapse from '../components/ListItemCollapse';

const EzAuthAdminPage: React.FC = () => {
  const { allUsersLinks, composeUrl } = useEzAuth(false, false);

  const [modalLink, setModalLink] = useState<EzAuthLink | null>(null);

  return (
    <>
      <div className="flex h-full justify-center">
        <div className="w-2/3">
          <div className="w-full pt-8">
            <div className="flex items-end justify-between">
              <div className="flex items-center gap-2">
                <div className="text-xl font-bold">{t('ezAuth.admin.label.allUserLinks')}</div>
              </div>
            </div>
            <div className="mt-2 border-b border-gray"></div>
            <div className="h-4/5 overflow-x-hidden overflow-y-scroll border-b border-gray pr-1 scrollbar-thin scrollbar-thumb-aws-font-color/20">
              {allUsersLinks?.length === 0 && (
                <div className="flex size-full items-center justify-center italic text-dark-gray">
                  {t('ezAuth.admin.label.noLinks')}
                </div>
              )}
              {allUsersLinks?.map((UserLinks, userIdx) => (
                <>
                  {UserLinks?.length === 0 ? <></> :
                    <ListItemCollapse
                      title={UserLinks?.[0]?.userId}>
                      <>
                        {(
                          UserLinks?.map((UserLink, linkIdx) => (
                            <ListItemLink
                              link={UserLink}
                              onClick={setModalLink}
                              key={`${userIdx}_${linkIdx}`}
                            >
                              <div className="flex items-center">

                                <div className="mr-5 flex justify-end">
                                </div>

                                <div className="mr-5">
                                </div>

                                <Button
                                  className="mr-2 h-8 text-sm font-semibold"
                                  outlined
                                  onClick={() => { return }}>
                                  {t("ezAuth.admin.label.editButton")}
                                </Button>
                              </div>
                            </ListItemLink>
                          ))
                        )}
                      </>
                    </ListItemCollapse>
                  }
                </>
              ))}
            </div >
          </div>
        </div>
      </div>
      <ModalDialog
        isOpen={modalLink !== null}
        onClose={() => { setModalLink(null) }}
        title='QRコード詳細'
        className='flex'
      >
        {modalLink && <div>
          <div className=''>
            認証ID:{modalLink?.id}<br />
            ユーザID:{modalLink?.userId}<br />
            状態:{modalLink?.disabled ? t("ezAuth.admin.label.disabled") : t("ezAuth.admin.label.enabled")}<br />
            有効期限:{formatDatetime(new Date(modalLink?.expireTimestamp * 1000))}<br />
            作成日:{formatDatetime(new Date(modalLink?.createTimestamp * 1000))}<br />
            最終更新日:{formatDatetime(new Date(modalLink?.updateTimestamp * 1000))}<br />
          </div>
          <div className=''>
            <QRCodeSVG value={composeUrl(modalLink)} bgColor={'transparent'}></QRCodeSVG>
          </div>
        </div>}
      </ModalDialog>
    </>
  );
};

export default EzAuthAdminPage;
