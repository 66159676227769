import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ButtonSend from '../../../components/ButtonSend';
import Textarea from '../../../components/Textarea';
import useChat from '../../../hooks/useChat';
import Button from '../../../components/Button';
import { PiArrowsCounterClockwise } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { BaseProps } from '../../../@types/common';
import ButtonIcon from '../../../components/ButtonIcon';
import { Tooltip } from 'react-tooltip';
import { MdKeyboardVoice, MdSettingsVoice } from 'react-icons/md';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import ExampleButtons from '../../../components/ExampleButtons';
import { ExampleItem } from '../../../@types/bot';
import { RiMicOffFill } from 'react-icons/ri';

type Props = BaseProps & {
  disabledSend?: boolean;
  disabled?: boolean;
  placeholder?: string;
  dndMode?: boolean;
  onSend: (content: string, base64EncodedImages?: string[]) => void;
  onRegenerate: () => void;
  onSendExample: (e: React.MouseEvent) => void;
  examples?: ExampleItem[];
};

export const TextInputChatContent: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { postingMessage, hasError, messages } = useChat();

  const [content, setContent] = useState('');

  const disabledExamples = useMemo(() => {
    return props.disabledSend || hasError;
  }, [hasError, props.disabledSend]);

  const disabledSend = useMemo(() => {
    return content === '' || props.disabledSend || hasError;
  }, [hasError, content, props.disabledSend]);

  const disabledRegenerate = useMemo(() => {
    return postingMessage || hasError;
  }, [hasError, postingMessage]);



  // 参考：https://bgtu-techblog.com/2023/05/21/%E9%9F%B3%E5%A3%B0%E8%AA%8D%E8%AD%98%E3%81%A7%E9%9F%B3%E5%A3%B0%E3%82%92%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E5%8C%96%E3%81%99%E3%82%8Breact%E3%83%95%E3%83%83%E3%82%AF%E3%80%90react-speech-recognition/
  const {
    interimTranscript,
    finalTranscript,
    listening,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (listening) {
      setContent(interimTranscript);
    }
  }, [listening, interimTranscript])

  useEffect(() => {
    setContent(finalTranscript);
  }, [finalTranscript])


  const inputRef = useRef<HTMLDivElement>(null);

  const sendContent = useCallback(() => {
    props.onSend(content, undefined);
    setContent('');
  }, [content, props]);

  useEffect(() => {
    const currentElem = inputRef?.current;
    const keypressListener = (e: DocumentEventMap['keypress']) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (!disabledSend) {
          sendContent();
        }
      }
    };
    currentElem?.addEventListener('keypress', keypressListener);

    const pasteListener = (e: DocumentEventMap['paste']) => {
      const clipboardItems = e.clipboardData?.items;
      if (!clipboardItems || clipboardItems.length === 0) {
        return;
      }
    };
    currentElem?.addEventListener('paste', pasteListener);

    return () => {
      currentElem?.removeEventListener('keypress', keypressListener);
      currentElem?.removeEventListener('paste', pasteListener);
    };
  });

  return (
    <>
      <ExampleButtons
        examples={props.examples}
        onSendExample={props.onSendExample}
        disabled={disabledExamples}
      ></ExampleButtons>
      <div
        ref={inputRef}
        className={twMerge(
          props.className,
          'relative mb-7 flex w-11/12 flex-col rounded-xl border border-black/10 bg-white shadow-[0_0_30px_7px] shadow-light-gray md:w-10/12 lg:w-4/6 xl:w-3/6'
        )}>
        <div className="flex w-full">
          <Textarea
            className={twMerge(
              'm-1  bg-transparent pr-6 scrollbar-thin scrollbar-thumb-light-gray'
            )}
            placeholder={props.placeholder ?? t('app.inputMessage')}
            disabled={props.disabled}
            noBorder
            value={content}
            onChange={setContent}
          />
        </div>
        <div className="absolute bottom-0 right-0 flex items-center">
          {browserSupportsSpeechRecognition && <ButtonIcon
            className="m-2 align-tombot"
            onClick={listening ? SpeechRecognition.stopListening : SpeechRecognition.startListening}
            disabled={!isMicrophoneAvailable || postingMessage}
          >
            <Tooltip id="speechRecognitionTooltip" />
            <div
              data-tooltip-id="speechRecognitionTooltip"
              data-tooltip-content={isMicrophoneAvailable ? t('bot.help.speechRecognition.normal') : t('bot.help.speechRecognition.error')}>
              {isMicrophoneAvailable ? listening ? <MdSettingsVoice /> : <MdKeyboardVoice /> : <RiMicOffFill />}
            </div>

          </ButtonIcon>}
          <ButtonSend
            className="m-2 align-bottom"
            disabled={disabledSend || props.disabled}
            loading={postingMessage}
            onClick={sendContent}
          />
        </div>

        {messages.length > 1 && (
          <Button
            className="absolute -top-14 right-0 bg-aws-paper p-2 text-sm"
            outlined
            disabled={disabledRegenerate || props.disabled}
            onClick={props.onRegenerate}>
            <PiArrowsCounterClockwise className="mr-2" />
            {t('button.regenerate')}
          </Button>
        )}
      </div>
    </>
  );
};
