import React, { useEffect, useState } from 'react';
import { translations } from '@aws-amplify/ui-react';
import { Auth, Hub, I18n } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import AuthAmplify from './components/AuthAmplify';
import AuthCustom from './components/AuthCustom';
import { Authenticator } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';
import './i18n';
import MPIcon from './assets/logo_w.png';
import SignInButton from './components/FederatedSignInButton';
import { validateSocialProvider } from './utils/SocialProviderUtils';
import AppContent from './components/AppContent';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './pages/ErrorFallback';
import { useLocation, useNavigate } from 'react-router-dom';

const customProviderEnabled =
  import.meta.env.VITE_APP_CUSTOM_PROVIDER_ENABLED === 'true';
const socialProviderFromEnv = import.meta.env.VITE_APP_SOCIAL_PROVIDERS?.split(
  ','
).filter(validateSocialProvider);
const MISTRAL_ENABLED: boolean =
  import.meta.env.VITE_APP_ENABLE_MISTRAL === 'true';

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userState = useState<CognitoUser | null>(null);
  const queryParams = new URLSearchParams(useLocation().search);
  const from_mp = queryParams.get('from') == 'mp_app'

  const [hidePage, setHidePage] = useState<boolean>(true);
  const [singIn, setSingIn] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // set header title
    document.title = !MISTRAL_ENABLED
      ? t('app.name')
      : t('app.nameWithoutClaude');
    if (from_mp) {
      // HPからのアクセスの場合自動サインインを有効化する
      // サインインしているかを確認する
      Auth.currentAuthenticatedUser().then(user => {
        userState[1](user);
        if (queryParams.has('bot_id')) {
          const botId = queryParams.get('bot_id');
          queryParams.delete('bot_id');
          navigate(`/bot/${botId}?` + queryParams.toString());  // パラメータに従って画面遷移する
          navigate(0); // リロード
        }
        setHidePage(false);
      }).catch(() => {
        setHidePage(false);
        setSingIn(true);
      })
    } else {
      setHidePage(false);
    }

    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "customOAuthState":
          // Oauthから帰ってきたときにクエリパラメータを復活させて再度移動
          navigate({
            pathname: location.pathname,
            search: data ?? ""
          })
      }
    });
    return unsubscribe;

  }, []);

  I18n.putVocabularies(translations);
  I18n.setLanguage(i18n.language);

  return (
    <>
      {singIn && <>
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="flex flex-col items-center text-3xl text-aws-font-color">
            <div>
              <img src={MPIcon} />{t('app.name')}
            </div>
            <div>
              <SignInButton></SignInButton>
            </div>
          </div>
        </div>
      </>}
      {
        !hidePage && !singIn && <ErrorBoundary fallback={<ErrorFallback />}>
          {customProviderEnabled ? (
            <AuthCustom>
              <AppContent />
            </AuthCustom>
          ) : (
            <Authenticator.Provider>
              <AuthAmplify socialProviders={socialProviderFromEnv}>
                <AppContent />
              </AuthAmplify>
            </Authenticator.Provider>
          )}
        </ErrorBoundary>
      }
    </>
  );
};

export default App;
