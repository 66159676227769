import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import InputChatContent from '../components/InputChatContent';
import useChat from '../hooks/useChat';
import ChatMessage from '../components/ChatMessage';
import useScroll from '../hooks/useScroll';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  PiArrowsCounterClockwise,
  PiLink,
  PiPencilLine,
  PiStar,
  PiStarFill,
  PiWarningCircleFill,
} from 'react-icons/pi';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import SwitchBedrockModel from '../components/SwitchBedrockModel';
import useBot from '../hooks/useBot';
import useConversation from '../hooks/useConversation';
import ButtonPopover from '../components/PopoverMenu';
import PopoverItem from '../components/PopoverItem';

import { copyBotUrl } from '../utils/BotUtils';
import { produce } from 'immer';
import ButtonIcon from '../components/ButtonIcon';
import StatusSyncBot from '../components/StatusSyncBot';
import Alert from '../components/Alert';
import useBotSummary from '../hooks/useBotSummary';
import useModel from '../hooks/useModel';
import { TextInputChatContent } from '../features/agent/components/TextInputChatContent';
import { ExampleItem } from '../@types/bot';
import ScreenBot from '../components/ScreenBot';
import { RiRobot2Fill, RiRobot2Line } from 'react-icons/ri';
import FaceExpressions from '../components/FaceExpressions';
import FaceExpressionsIndicator from '../components/FaceExpressionsIndicator';

const MISTRAL_ENABLED: boolean =
  import.meta.env.VITE_APP_ENABLE_MISTRAL === 'true';

const ChatPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const from_mp = queryParams.get('from') == 'mp_app'

  const [examples, setExamples] = useState<ExampleItem[]>([]);
  const [screenBotMode, setScreenBotMode] = useState<boolean>(false);
  const {
    postingMessage,
    postChat,
    messages,
    conversationId,
    setConversationId,
    hasError,
    retryPostChat,
    setCurrentMessageId,
    regenerate,
    getPostedModel,
    loadingConversation,
  } = useChat();

  const { getBotId } = useConversation();

  const { scrollToBottom, scrollToTop } = useScroll();

  const { conversationId: paramConversationId, botId: paramBotId } =
    useParams();

  const botId = useMemo(() => {
    return paramBotId ?? getBotId(conversationId);
  }, [conversationId, getBotId, paramBotId]);

  const {
    data: bot,
    error: botError,
    isLoading: isLoadingBot,
    mutate: mutateBot,
  } = useBotSummary(botId ?? undefined);

  const [pageTitle, setPageTitle] = useState('');
  const [isAvailabilityBot, setIsAvailabilityBot] = useState(false);

  useEffect(() => {
    setIsAvailabilityBot(false);
    if (bot) {
      setIsAvailabilityBot(true);
      setPageTitle(bot.title);
      setExamples(bot.examples)
    } else {
      setPageTitle(t('bot.label.normalChat'));
    }
    if (botError) {
      if (botError.response?.status === 404) {
        setPageTitle(t('bot.label.notAvailableBot'));
      }
    }
  }, [bot, botError, t]);

  const description = useMemo<string>(() => {
    if (!bot) {
      return '';
    } else if (bot.description === '') {
      return t('bot.label.noDescription');
    } else {
      return bot.description;
    }
  }, [bot, t]);

  const disabledInput = useMemo(() => {
    return botId !== null && !isAvailabilityBot && !isLoadingBot;
  }, [botId, isAvailabilityBot, isLoadingBot]);

  useEffect(() => {
    setConversationId(paramConversationId ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramConversationId]);

  const inputBotParams = useMemo(() => {
    return botId
      ? {
        botId: botId,
        hasKnowledge: bot?.hasKnowledge ?? false,
        hasAgent: bot?.hasAgent ?? false,
      }
      : undefined;
  }, [bot?.hasKnowledge, botId, bot?.hasAgent]);

  const onSend = useCallback(
    (content: string, base64EncodedImages?: string[]) => {
      postChat({
        content,
        base64EncodedImages,
        bot: inputBotParams,
      });
    },
    [inputBotParams, postChat]
  );

  const onSendExample = useCallback((e: React.MouseEvent) => {
    postChat({
      content: e.currentTarget.textContent ? e.currentTarget.textContent : '',
      bot: inputBotParams,
    });
  }, [inputBotParams, postChat]);

  const onChangeCurrentMessageId = useCallback(
    (messageId: string) => {
      setCurrentMessageId(messageId);
    },
    [setCurrentMessageId]
  );

  const onSubmitEditedContent = useCallback(
    (messageId: string, content: string) => {
      if (hasError) {
        retryPostChat({
          content,
          bot: inputBotParams,
        });
      } else {
        regenerate({
          messageId,
          content,
          bot: inputBotParams,
        });
      }
    },
    [hasError, inputBotParams, regenerate, retryPostChat]
  );

  const onRegenerate = useCallback(() => {
    regenerate({
      bot: inputBotParams,
    });
  }, [inputBotParams, regenerate]);

  useLayoutEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    } else {
      scrollToTop();
    }
  }, [messages, scrollToBottom, scrollToTop]);

  const { updateMyBotStarred, updateSharedBotStarred } = useBot();
  const onClickBotEdit = useCallback(
    (botId: string) => {
      navigate(`/bot/edit/${botId}`);
    },
    [navigate]
  );

  const onClickStar = useCallback(() => {
    if (!bot) {
      return;
    }
    const isStarred = !bot.isPinned;
    mutateBot(
      produce(bot, (draft) => {
        draft.isPinned = isStarred;
      }),
      {
        revalidate: false,
      }
    );

    try {
      if (bot.owned) {
        updateMyBotStarred(bot.id, isStarred);
      } else {
        updateSharedBotStarred(bot.id, isStarred);
      }
    } finally {
      mutateBot();
    }
  }, [bot, mutateBot, updateMyBotStarred, updateSharedBotStarred]);

  const [copyLabel, setCopyLabel] = useState(t('bot.titleSubmenu.copyLink'));
  const onClickCopyUrl = useCallback(
    (botId: string) => {
      copyBotUrl(botId);
      setCopyLabel(t('bot.titleSubmenu.copiedLink'));
      setTimeout(() => {
        setCopyLabel(t('bot.titleSubmenu.copyLink'));
      }, 3000);
    },
    [t]
  );

  const onClickSyncError = useCallback(() => {
    navigate(`/bot/edit/${bot?.id}`);
  }, [bot?.id, navigate]);

  const { disabledImageUpload } = useModel();
  const [dndMode, setDndMode] = useState(false);
  const onDragOver: React.DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!disabledImageUpload) {
        setDndMode(true);
      }
      e.preventDefault();
    },
    [disabledImageUpload]
  );

  const endDnd: React.DragEventHandler<HTMLDivElement> = useCallback((e) => {
    setDndMode(false);
    e.preventDefault();
  }, []);

  return (
    <div
      className="relative flex h-full flex-1 flex-col"
      onDragOver={onDragOver}
      onDrop={endDnd}
      onDragEnd={endDnd}>
      <div className="flex-1 overflow-hidden">
        <div className="sticky top-0 z-10 mb-1.5 flex h-14 w-full items-center justify-between border-b border-gray bg-aws-paper p-2 font-semibold">
          <div className="flex w-full justify-between">
            <div className="p-2">
              <div className="mr-10 font-bold">{pageTitle}</div>
              <div className="text-xs font-thin text-dark-gray">
                {description}
              </div>
            </div>
            <div className="absolute -top-1 right-0 flex h-full items-center">
              <div className="h-full w-5 bg-gradient-to-r from-transparent to-aws-paper"></div>
              <FaceExpressionsIndicator></FaceExpressionsIndicator>
              <FaceExpressions
                className="m-2 align-tombot"></FaceExpressions>
              <ButtonIcon className='text-end bg-aws-paper' onClick={() => { setScreenBotMode(!screenBotMode) }}>

                {screenBotMode ? (
                  <RiRobot2Fill className="text-aws-aqua" />
                ) : (
                  <RiRobot2Line />
                )}
              </ButtonIcon>

              {isAvailabilityBot && (
                <>
                  <div className="h-full w-2 bg-gradient-to-r from-transparent to-aws-paper"></div>
                  {!from_mp &&
                    <div className="flex items-center bg-aws-paper">
                      {bot?.owned && (
                        <StatusSyncBot
                          syncStatus={bot.syncStatus}
                          onClickError={onClickSyncError}
                        />
                      )}
                      <ButtonIcon onClick={onClickStar}>
                        {bot?.isPinned ? (
                          <PiStarFill className="text-aws-aqua" />
                        ) : (
                          <PiStar />
                        )}
                      </ButtonIcon>
                      <ButtonPopover className="mx-1" target="bottom-right">
                        {bot?.owned && (
                          <PopoverItem
                            onClick={() => {
                              if (bot) {
                                onClickBotEdit(bot.id);
                              }
                            }}>
                            <PiPencilLine />
                            {t('bot.titleSubmenu.edit')}
                          </PopoverItem>
                        )}
                        {bot?.isPublic && (
                          <PopoverItem
                            onClick={() => {
                              if (bot) {
                                onClickCopyUrl(bot.id);
                              }
                            }}>
                            <PiLink />
                            {copyLabel}
                          </PopoverItem>
                        )}
                      </ButtonPopover>
                    </div>
                  }
                </>
              )}
            </div>
          </div>
          {!from_mp && getPostedModel() && (
            <div className="absolute right-2 top-10 text-xs text-dark-gray">
              model: {getPostedModel()}
            </div>
          )}
        </div>
        <section className="relative size-full flex-1 overflow-auto pb-9">
          <div className="h-full">
            <div
              id="messages"
              role="presentation"
              className={screenBotMode ? "" : "flex h-full flex-col overflow-auto pb-[10rem]"}>
              {messages.length === 0 && (
                <div className="relative flex w-full justify-center">
                  {!loadingConversation && (
                    <SwitchBedrockModel className="mt-3 flex-wrap" />
                  )}
                  {!bot?.firstContact && !screenBotMode && <div className="absolute mx-3 my-20 flex items-center justify-center text-4xl font-bold text-gray">
                    {!MISTRAL_ENABLED
                      ? t('app.name')
                      : t('app.nameWithoutClaude')}
                  </div>}
                </div>
              )}
              {screenBotMode && (<>
                <div className='flex items-center justify-center min-h-full'>
                  <ScreenBot
                    onSend={onSend}></ScreenBot>
                </div>
                <div className="z-0 flex w-full flex-col items-center justify-center">
                  {bot && bot.syncStatus !== 'SUCCEEDED' && (
                    <div className="mb-8 w-1/2">
                      <Alert
                        severity="warning"
                        title={t('bot.alert.sync.incomplete.title')}>
                        {t('bot.alert.sync.incomplete.body')}
                      </Alert>
                    </div>
                  )}
                </div>
              </>
              )}
              {!screenBotMode && (
                <>
                  {bot?.firstContact && (
                    <div
                      className='bg-aws-squid-ink/5'>
                      <ChatMessage
                        chatContent={{ role: 'assistant', content: [{ contentType: 'text', body: bot.firstContact }], model: 'claude-v2', id: 'a', parent: null, children: [], sibling: [], feedback: null, usedChunks: null }}
                      />
                      <div className="w-full border-b border-aws-squid-ink/10"></div>
                    </div>
                  )}
                  {messages.map((message, idx) => (
                    <div
                      key={idx}
                      className={`${message.role === 'assistant' ? 'bg-aws-squid-ink/5' : ''
                        }`}>
                      <ChatMessage
                        chatContent={message}
                        onChangeMessageId={onChangeCurrentMessageId}
                        onSubmit={onSubmitEditedContent}
                      />
                      <div className="w-full border-b border-aws-squid-ink/10"></div>
                    </div>
                  ))}
                </>
              )}
              {screenBotMode && (
                <>
                  {!messages && bot?.firstContact && (
                    <div
                      className='bg-aws-squid-ink/5'>
                      <ChatMessage
                        chatContent={{ role: 'assistant', content: [{ contentType: 'text', body: bot.firstContact }], model: 'claude-v2', id: 'a', parent: null, children: [], sibling: [], feedback: null, usedChunks: null }}
                      />
                      <div className="w-full border-b border-aws-squid-ink/10"></div>
                    </div>
                  )}
                  {messages.slice(-1).map((message, idx) => (
                    <div
                      key={idx}
                      className={`${message.role === 'assistant' ? 'bg-aws-squid-ink/5' : ''
                        }`}>
                      <ChatMessage
                        chatContent={message}
                        onChangeMessageId={onChangeCurrentMessageId}
                        onSubmit={onSubmitEditedContent}
                      />
                      <div className="w-full border-b border-aws-squid-ink/10"></div>
                    </div>
                  ))}
                </>
              )}
              {hasError && (
                <div className="mb-12 mt-2 flex flex-col items-center">
                  <div className="flex items-center font-bold text-red">
                    <PiWarningCircleFill className="mr-1 text-2xl" />
                    {t('error.answerResponse')}
                  </div>

                  <Button
                    className="mt-2 shadow "
                    icon={<PiArrowsCounterClockwise />}
                    outlined
                    onClick={() => {
                      retryPostChat({
                        bot: inputBotParams,
                      });
                    }}>
                    {t('button.resend')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div >

      {!screenBotMode && (
        <div className="absolute bottom-0 z-0 flex w-full flex-col items-center justify-center">
          {!from_mp &&bot && bot.syncStatus !== 'SUCCEEDED' && (
            <div className="mb-8 w-1/2">
              <Alert
                severity="warning"
                title={t('bot.alert.sync.incomplete.title')}>
                {t('bot.alert.sync.incomplete.body')}
              </Alert>
            </div>
          )}
          {bot?.hasAgent ? (
            <TextInputChatContent
              dndMode={dndMode}
              disabledSend={postingMessage}
              disabled={disabledInput}
              placeholder={
                disabledInput
                  ? t('bot.label.notAvailableBotInputMessage')
                  : undefined
              }
              onSend={onSend}
              onSendExample={onSendExample}
              onRegenerate={onRegenerate}
              examples={examples}
            />
          ) : (
            <InputChatContent
              dndMode={dndMode}
              disabledSend={postingMessage}
              disabled={disabledInput}
              placeholder={
                disabledInput
                  ? t('bot.label.notAvailableBotInputMessage')
                  : undefined
              }
              onSend={onSend}
              onSendExample={onSendExample}
              onRegenerate={onRegenerate}
              examples={examples}
            />
          )}
        </div>
      )}
    </div >
  );
};

export default ChatPage;
