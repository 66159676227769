import { CreateLinkRequest, CreateLinkResponse, GetLinksRequest, GetLinksResponse, GetUsernameRequest, GetUsernameResponse } from '../@types/ez-auth';
import useHttp from './useHttp';

const useEzAuthApi = () => {
  const http = useHttp();

  return {
    createLink: (params: CreateLinkRequest) => {
      return http.post<CreateLinkResponse>('/ez_auth/create-link', params);
    },
    getUserName: (params: GetUsernameRequest) => {
      return http.post<GetUsernameResponse>('/ez_auth/get-user-name', params);
    },
    links: (
      req: GetLinksRequest,
      refreshIntervalFunction?: (data?: GetLinksResponse) => number
    ) => {
      return http.get<GetLinksResponse>(['/ez_auth/get-link-list', req], {
        refreshInterval: refreshIntervalFunction,
      });
    },
  };
};

export default useEzAuthApi;
