import { Howl } from 'howler';
import { useRef } from 'react';

// カスタムフックを使用して、動的にURLを変更しオーディオを再生する
const useDynamicAudio = () => {
    const sound = useRef<Howl | null>(null);

    return {

        stop: () => {
            // 既存のサウンドがあれば停止して破棄
            if (sound.current) {
                sound.current.unload();
                sound.current = null;
            }
        },

        play: (url: string, format: string, callBack?: () => void, /*startTime: number = 0, stopTime: number = 0*/) => {
            // 既存のサウンドがあれば停止して破棄
            stop();

            // 新しいサウンドインスタンスを作成
            sound.current = new Howl({
                src: [url],
                format: format,
                html5: true, // HTML5オーディオを強制する

            });
            // sound.current.seek(startTime);

            sound.current.play();
            if (callBack) {
                sound.current.once('end', callBack);
            }
            // setTimeout(() => {
            //     sound.current?.unload();
            // }, (sound.current.duration() - stopTime) * 1000)
        },
    }
}

export default useDynamicAudio;
