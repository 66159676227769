import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BaseProps } from '../@types/common';
import useChat from '../hooks/useChat';
import useSpeech from '../hooks/useSpeech';
import { useFaceRecognition } from "../hooks/useFaceRecognition"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { useTranslation } from 'react-i18next';
import { Mutex } from "await-semaphore"
import "setimmediate"
import { decodeTime, isValid as isULID } from 'ulidx';

type Props = BaseProps & {
  onSend: (content: string, base64EncodedImages?: string[]) => void;
};
type characterNames = "nak" | "female" | "male" | "nurseFemale" | "nurseMale" | "teacherFemale" | "teacherMale";
const characters: Map<characterNames, {
  displayName: string;
  speeching: string;
  generating: string;
  listing: string;
  default: string;
  voice: string;
}> = new Map([
  [
    "nak", {
      "displayName": "ナック君",
      "speeching": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nak/speeching.gif`,
      "generating": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nak/generating.png`,
      "listing": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nak/listing.png`,
      "default": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nak/default.png`,
      "voice": "Tomoko",
    }
  ], [
    "female", {
      "displayName": "女性",
      "speeching": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalFemale/speeching.webp`,
      "generating": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalFemale/generating.webp`,
      "listing": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalFemale/listing.webp`,
      "default": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalFemale/default.webp`,
      "voice": "Kazuha",
    }
  ], [
    "male", {
      "displayName": "男性",
      "speeching": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalMale/speeching.webp`,
      "generating": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalMale/generating.webp`,
      "listing": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalMale/listing.webp`,
      "default": `${process.env.PUBLIC_URL ?? ""}/screen-bot/formalMale/default.webp`,
      "voice": "Takumi",
    }
  ], [
    "nurseFemale", {
      "displayName": "女性看護師",
      "speeching": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseFemale/speeching.webp`,
      "generating": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseFemale/generating.webp`,
      "listing": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseFemale/listing.webp`,
      "default": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseFemale/default.webp`,
      "voice": "Kazuha",
    }
  ], [
    "nurseMale", {
      "displayName": "男性看護師",
      "speeching": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseMale/speeching.webp`,
      "generating": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseMale/generating.webp`,
      "listing": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseMale/listing.webp`,
      "default": `${process.env.PUBLIC_URL ?? ""}/screen-bot/nurseMale/default.webp`,
      "voice": "Takumi",
    }
  ], [
    "teacherFemale", {
      "displayName": "女性教師",
      "speeching": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherFemale/speeching.webp`,
      "generating": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherFemale/generating.webp`,
      "listing": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherFemale/listing.webp`,
      "default": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherFemale/default.webp`,
      "voice": "Takumi",
    }
  ], [
    "teacherMale", {
      "displayName": "男性教師",
      "speeching": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherMale/speeching.webp`,
      "generating": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherMale/generating.webp`,
      "listing": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherMale/listing.webp`,
      "default": `${process.env.PUBLIC_URL ?? ""}/screen-bot/teacherMale/default.webp`,
      "voice": "Takumi",
    }
  ]
]);


type faceExpressionType = "neutral" | "happy" | "sad" | "angry" | "fearful" | "disgusted" | "surprised";
const facerecogPattern = new Map<faceExpressionType, string[]>([
  [
    "neutral", [
      "今日の調子はどうですか？",
      "最近、何か面白いことありましたか？",
      "何か困っていることはありませんか？",
      "今日はどんな一日でしたか？",
      "何か話したいことがあれば聞かせてください。",
    ]
  ], [
    "happy", [
      "今日はすごくいいことがあったみたいですね！何か嬉しいことがあったんですか？",
      "その笑顔、何か楽しいことがあったんですね？教えてください！",
      "何か特別な理由でこんなに嬉しそうなの？",
      "最近、何か嬉しいニュースでもあったの？",
      "その幸せそうな顔を見ると、こちらも元気になります！",
    ]
  ], [
    "sad", [
      "何かあったの？話してくれると嬉しいです。",
      "元気がないみたいだけど、大丈夫ですか？",
      "何か困っていることがあるなら、言ってくださいね。力になりたいです。",
      "どうしたの？何かできることがあれば教えてください。",
      "話すことで少しでも気持ちが楽になるかもしれないので、よかったら聞かせてください。",
    ]
  ], [
    "angry", [
      "何かあったの？落ち着いて話してくれると嬉しいです。",
      "怒っているみたいだけど、何かあったら教えてください。",
      "その怒りの原因がわかれば、力になれるかもしれません。",
      "今、何に対して怒っているのか話してくれますか？",
      "あなたの気持ちを理解したいので、どうしたのか教えてください。",
    ]
  ], [
    "fearful", [
      "何か怖いことがあったの？話してくれたら助けになれるかもしれないよ。",
      "心配そうな顔してるけど、大丈夫？何があったのか教えてくれる？",
      "何か不安なことがあったら、一緒に解決できるかもしれないよ。",
      "怖がっているように見えるけど、何かあったら話してみてください。",
      "何が起こったのか教えてくれると、少しでも安心できるかもしれません。",
    ]
  ], [
    "disgusted", [
      "何か嫌なことがあったみたいですね。話してくれますか？",
      "その表情、何か気になることがあったの？",
      "何か気に障ることがあったなら、教えてください。",
      "不快な思いをしているようですが、何が原因ですか？",
      "何か嫌なことがあったら、話してくれるといいかもしれません。",
    ]
  ], [
    "surprised", [
      "驚いているみたいだけど、何かあったの？",
      "その表情、何かびっくりするようなことがあったの？",
      "驚いた顔してるけど、何が起こったのか教えてくれる？",
      "そんなに驚いている理由を聞かせてください。",
      "何に驚いているのか、ちょっと話してみてくれませんか？",
    ]
  ]
])

const ScreenBot: React.FC<Props> = (props) => {

  // 状態の設定
  const { postingMessage, messages } = useChat();
  const {
    finalTranscript,
    listening,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition, } = useSpeechRecognition();
  const { speechStatus, onPlaySpeechStreaming, onStopSpeech, setVoiceId } = useSpeech();
  const { detectedFaceExpressions } = useFaceRecognition();
  const { t } = useTranslation();
  const [listend, setListend] = useState<boolean>(false);
  const [previousMessageId, setPreviousMessageId] = useState<string>("");
  const speechedBodyLatest = useRef<string>("");
  const [lastSend, setLastSend] = useState<number>(0);
  const [character, setCharacter] = useState<characterNames | "">("");


  const speechingImg = useMemo(() => {
    if (character == "") {
      return ""
    }
    const image = characters.get(character)?.speeching;
    if (!image) {
      throw new Error("ScreenBot character settings error.")
    }
    return image;
  }, [character]);
  const generatingImg = useMemo(() => {
    if (character == "") {
      return ""
    }
    const image = characters.get(character)?.generating;
    if (!image) {
      throw new Error("ScreenBot character settings error.")
    }
    return image;
  }, [character]);
  const listingImg = useMemo(() => {
    if (character == "") {
      return ""
    }
    const image = characters.get(character)?.listing;
    if (!image) {
      throw new Error("ScreenBot character settings error.")
    }
    return image;
  }, [character]);
  const defaultImg = useMemo(() => {
    if (character == "") {
      return ""
    }
    const image = characters.get(character)?.default;
    if (!image) {
      throw new Error("ScreenBot character settings error.")
    }
    return image;
  }, [character]);
  useEffect(() => {
    if (character == "") {
      return;
    }
    const voiceId = characters.get(character)?.voice;
    if (voiceId) {
      setVoiceId(voiceId)
    }
  }, [character, setVoiceId])

  // 状態を判定
  const getFace = useMemo(() => {
    if (speechStatus == 'speeching') {
      return speechingImg; // しゃべっている画像
    }
    if (postingMessage) {
      return generatingImg; // 考えている画像
    }
    if (listening) {
      setListend(true);
      return listingImg; // 聞いている画像
    }
    return defaultImg; // デフォルトの画像
  }, [speechStatus, postingMessage, listening, defaultImg, speechingImg, generatingImg, listingImg]);

  const onClickScreenBot = useCallback(() => {
    if (speechStatus == 'speeching') {
      onStopSpeech();
    }
    if (messages.length >= 1) {
      setPreviousMessageId(messages[messages.length - 1].id);
    }

    SpeechRecognition.startListening();
  }, [messages, onStopSpeech, speechStatus]);

  useEffect(() => {
    // 聞き取ったら送信する
    if (finalTranscript) {
      const expression = detectedFaceExpressions?.asSortedArray()[0].expression;
      let hiddenContent = '<hiddenContent>';
      if (expression) {
        hiddenContent = hiddenContent + `ユーザは${expression}という表情をしています。`;
      }
      hiddenContent = hiddenContent + '音声で読み上げられることを意識してください。なるべく簡潔に、読み上げやすいような内容にしてください。ただし会話が続くようにしてください。';
      hiddenContent = hiddenContent + `これはsystem_messageであり個々で与えられた情報を直接返答に含めてはいけません。`;
      hiddenContent = hiddenContent + '</hiddenContent>';
      props.onSend(`${hiddenContent}${finalTranscript}`);
    }
    speechedBodyLatest.current = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalTranscript]);

  // 顔が検出されたときの処理
  useEffect(() => {
    if (!character) { return; }
    if (detectedFaceExpressions == null) { return; }
    if (!postingMessage) {
      const _checkTalkFromScreenbot = () => {
        if (messages.length == 0) return true;
        const now = new Date().getTime();
        const lastMessageTimestamp = isULID(messages.slice(-1)[0].id) ? decodeTime(messages.slice(-1)[0].id) : now;
        // 1分以上無応答の場合にしゃべりかける。
        if (now - lastMessageTimestamp > 60 * 1000) return true;
        return false;
      }
      if (_checkTalkFromScreenbot()) {
        const expression = detectedFaceExpressions.asSortedArray()[0].expression as faceExpressionType;
        const messageList = facerecogPattern.get(expression);
        if (messageList == null) { throw new Error('FaceRecognition Message Settings Error'); }
        const randomIndex = Math.floor(Math.random() * messageList.length);
        const message = messageList[randomIndex];
        setListend(true); // 読み上げ処理を行うためのフラグ

        let sendMessage = '<hiddenContent>';
        if (expression) {
          sendMessage = sendMessage + `ユーザは${expression}という表情をしています。`;
        }
        sendMessage = sendMessage + `始めに「${message}」と言ってください。`;
        sendMessage = sendMessage + `これはsystem_messageでありhiddenContent内で与えられた情報を直接返答に含めてはいけません。`;
        sendMessage = sendMessage + '</hiddenContent>';
        props.onSend(sendMessage)
      }
    }
  }, [detectedFaceExpressions, props, postingMessage, character, messages.length, messages])

  useEffect(() => {
    (async () => {
      if (messages.length < 1) { return; }
      if (!listend) { return; }
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.id == previousMessageId) {
        // 前回のメッセージと同じ場合は処理しない
        return;
      }
      if (lastMessage.role != "assistant") {
        // アシスタントのメッセージ以外は読み上げない
        return;
      }
      let body: string = "";
      for (const content of lastMessage.content) {
        if (content.contentType == "text") {
          body = content.body;
          if (body.endsWith(t("app.chatWaitingSymbol"))) {
            body = body.slice(0, body.length - 1);
          }
        }
      }
      if (!body) {
        // 空白の場合は読み上げない
        return;
      }
      const mutex = new Mutex();
      const release = await mutex.acquire();
      try {
        const now = new Date().getTime();
        const newBodyLength = body.length - speechedBodyLatest.current.length;
        if (postingMessage) {
          // 受信途中でも読み上げを始める
          if (newBodyLength < 30 || (now - lastSend) < 1000) {
            // 新規追加が十文字以下の場合はまだ音声処理を進めない。
            // 最低でも1秒の間隔を空ける。
            return;
          }
        } else {
          // 聞き取ったと言う状態を解除
          setListend(false);
        }
        speechedBodyLatest.current = body;
        if (newBodyLength == 0) { return; }
        onPlaySpeechStreaming(body.slice(-newBodyLength));
        setLastSend(now);
      } finally {
        release();
      }
    })();
  }, [lastSend, listend, messages, onPlaySpeechStreaming, postingMessage, previousMessageId, speechedBodyLatest, t]);
  return (
    <>
      <div className='size-full'>
        {character ? (
          <>
            {!browserSupportsSpeechRecognition && t("screenBot.voiceRecognitionError")}
            {!isMicrophoneAvailable && t("screenBot.microphoneError")}
            <img src={getFace} className='mx-auto max-h-[60vh]' onClick={onClickScreenBot}></img>
          </>
        ) : (
          <div className='m-2'>
            <label>{t("screenBot.selectCharacter")}</label>
            <div className='grid grid-cols-4 gap-4'>
              {Array.from(characters).map(([name, character]) => {
                return <div key={name} onClick={() => { setCharacter(name) }}>
                  <img src={character.default}></img>
                  <label>{character.displayName}</label>
                </div>
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScreenBot;
