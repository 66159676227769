import { useRef } from "react";
import * as faceapi from "face-api.js";
import { create } from "zustand";

const MODEL_PATH = '/model'
const detectorOptions = new faceapi.TinyFaceDetectorOptions();

const useFaceRecognitionState = create<{
    isStarted: boolean;
    setIsStarted: (b: boolean) => void;
    isReady: boolean;
    setIsReady: (b: boolean) => void;
    detectedFace: faceapi.FaceDetection | null;
    setDetectedFace: (b: faceapi.FaceDetection | null) => void;
    detectedFaceExpressions: faceapi.FaceExpressions | null;
    setDetectedFaceExpressions: (b: faceapi.FaceExpressions | null) => void;
}>((set) => {
    return {
        isStarted: false,
        setIsStarted: (b: boolean) => {
            set(() => ({
                isStarted: b,
            }));
        },
        isReady: false,
        setIsReady: (b: boolean) => {
            set(() => ({
                isReady: b,
            }));
        },
        detectedFace: null,
        setDetectedFace: (b: faceapi.FaceDetection | null) => {
            set(() => ({
                detectedFace: b,
            }));
        },
        detectedFaceExpressions: null,
        setDetectedFaceExpressions: (b: faceapi.FaceExpressions | null) => {
            set(() => ({
                detectedFaceExpressions: b,
            }));
        },
    };
});


const useFaceRecognition = () => {

    const { isStarted, setIsStarted, isReady, setIsReady, detectedFace, setDetectedFace, detectedFaceExpressions, setDetectedFaceExpressions } = useFaceRecognitionState();
    const videoRef = useRef<HTMLVideoElement>(null)

    const startCam = async () => {
        console.debug("cam start");
        if (isStarted) {
            console.debug("cam already started");
            return;
        }
        setIsStarted(true)
        await Promise.all([
            // TensorFlow
            setupTensorFlow(),
            // FaceAPI
            setupModel(),
            // Camera
            setupCamera(),
        ])
        setIsReady(true)
        console.debug("cam started");
    }

    const stopCam = () => {
        console.debug("cam stop");
        if (videoRef.current) {
            const video = videoRef.current as HTMLVideoElement
            if (video && video.readyState >= 2) {
                const stream = video.srcObject as MediaStream;
                stream.getTracks().forEach(track => {
                    track.stop();
                    console.debug('Stopping track:', track);
                });
            }
        }
        setIsStarted(false)
        setIsReady(false)
        console.debug("cam stoped");
    }


    // SetUp Tensolflow
    const setupTensorFlow = async () => {
        await faceapi.tf.setBackend('webgl');
        await faceapi.tf.enableProdMode();
        await faceapi.tf.ready();
    }

    // Setup Model
    const setupModel = async () => {
        await faceapi.nets.tinyFaceDetector.load(MODEL_PATH); // using ssdMobilenetv1
        // await faceapi.nets.ageGenderNet.load(MODEL_PATH);
        // await faceapi.nets.faceLandmark68Net.load(MODEL_PATH);
        // await faceapi.nets.faceRecognitionNet.load(MODEL_PATH);
        await faceapi.nets.faceExpressionNet.load(MODEL_PATH);
    }

    // Setup Camera
    const setupCamera = async () => {
        if (videoRef.current) {
            const video = videoRef.current as HTMLVideoElement
            if (!navigator.mediaDevices) {
                throw new Error("Camera Error: access not supported");
            }
            const constraints = {
                audio: false,
                video: true,
            };
            let stream: MediaStream
            try {
                stream = await navigator.mediaDevices.getUserMedia(constraints);
            } catch (err) {
                let errName = ''
                let msg = ''
                if (err instanceof Error) {
                    if (err.name === 'PermissionDeniedError' || err.name === 'NotAllowedError') {
                        errName = 'camera permission denied';
                    } else if (err.name === 'SourceUnavailableError') {
                        errName = 'camera not available';
                    }
                    if (err.message) {
                        msg = err.message
                    }
                }
                throw new Error(`Camera Error: ${errName}: ${msg.length > 0 ? msg : err}`);
            }
            if (stream) {
                video.srcObject = stream
            } else {
                throw new Error("Camera Error: MediaStream Empty");
            }
            const track = stream.getVideoTracks()[0];
            track.applyConstraints(
                {
                    facingMode: 'user',
                    width: {
                        ideal: 2778,
                    },
                    height: {
                        ideal: 2778,
                    }
                });
            const settings = track.getSettings();
            if (settings.deviceId) {
                // Delete property / Release memory indirectly
                delete settings.deviceId;
            }
            if (settings.groupId) {
                delete settings.groupId;
            }
            if (settings.aspectRatio) {
                settings.aspectRatio = Math.trunc(100 * settings.aspectRatio) / 100;
            }
            video.onloadeddata = async () => {
                video.play();
                await detectHandler()
            };
        }


    }

    // Face Detection
    const detectHandler = async () => {
        if (videoRef.current) {
            const video = videoRef.current as HTMLVideoElement
            if (!video.paused) {
                const d = await faceapi
                    .detectSingleFace(video, detectorOptions)
                    // .withFaceLandmarks()
                    .withFaceExpressions() ?? null;
                // .withAgeAndGender()
                setDetectedFace(d?.detection ?? null)
                setDetectedFaceExpressions(d?.expressions ?? null)
                // For Performance
                // 限界性能
                // requestAnimationFrame(
                //     () => detectHandler()
                // )
                setTimeout(
                    () => detectHandler()
                    , 1000);
            }
        }
    }

    return {
        isStarted,
        startCam,
        stopCam,
        isReady,
        videoRef,
        detectedFace,
        detectedFaceExpressions
    }
}

export { useFaceRecognition }
