import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SignInButton: React.FC = () => {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(useLocation().search);
    const handleChangeStrage = () => {
        const keys = Object.keys(localStorage);
        const cognitoKeys = keys.filter(key => key.startsWith('CognitoIdentityServiceProvider'));
        if (cognitoKeys.length > 0) {
            navigate(0);
        } else {
            setTimeout(handleChangeStrage, 100)
        }
    }
    const handleSignIn = () => {
        const windowFeatures = 'width=800,height=600';
        window.open('/federate/authenticate?' + queryParams.toString(), '_blank', windowFeatures);
        setTimeout(handleChangeStrage, 100)
    };

    return <button onClick={handleSignIn}>サインイン</button>;
};

export default SignInButton;
