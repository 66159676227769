import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { PiSpinnerGap } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

type Props = {
    customProvider: string;
    customState?: string;
}

const AuthPopup: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        Auth.federatedSignIn({ customProvider: props.customProvider, customState: props.customState })
    }, []);

    return <div><PiSpinnerGap className="-ml-1 mr-2 animate-spin" />{t('app.authenticating')}</div>;
};

export default AuthPopup;
