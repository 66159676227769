import { twMerge } from 'tailwind-merge';
import { BaseProps } from '../@types/common';
import useModel from '../hooks/useModel';
import Button from './Button';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

type Props = BaseProps;

const SwitchBedrockModel: React.FC<Props> = (props) => {
  const { availableModels, modelId, setModelId } = useModel();

  const queryParams = new URLSearchParams(useLocation().search);
  const from_mp = queryParams.get('from') == 'mp_app'

  useEffect(() => {
    if (from_mp) { setModelId('claude-v3-sonnet') }
  }, [from_mp, setModelId])


  return (
    <>
      {!from_mp &&
    <div
      className={twMerge(
        props.className,
        'flex justify-center gap-2 rounded-lg border border-light-gray bg-light-gray p-1 text-sm'
      )}>
      {availableModels.map((availableModel) => (
        <Button
          key={availableModel.modelId}
          className={twMerge(
            'relative flex w-40 items-center rounded-lg p-2 font-bold',
            modelId === availableModel.modelId
              ? ''
              : 'border-light-gray bg-white text-dark-gray'
          )}
          onClick={() => setModelId(availableModel.modelId)}
          children={<>
            {availableModel.icon &&
              <div className={twMerge(
                'absolute text-[36px]',
                modelId === availableModel.modelId
                  ? 'text-yellow'
                  : 'text-sky-200'
              )
              }>
                {availableModel.icon}
              </div>
            }
            <span className='relative'>{availableModel.label}</span>
            {availableModel.badge &&
              <span className={twMerge(
                "absolute top-0 right-[5px] round px-2 py-1 text-xs font-bold translate-x-1/2 -translate-y-1/2 rounded-full rotate-12 z-10 hover:brightness-100 text-dark-gray",
                availableModel.badge == "Beta" ? "bg-light-yellow " : "",
                availableModel.badge == "New" ? "bg-green-200" : "",
                availableModel.badge == "Old" ? "bg-light-red" : "",
              )}>
                {availableModel.badge}
              </span>
            }
          </>}
        />
      ))}
    </div>
      }
    </>
  );
};

export default SwitchBedrockModel;
