import { create } from 'zustand';
import useBot from './useBot';
import useDynamicAudio from './useDynamicAudio';
import { useCallback, useEffect, useState } from 'react';
import { CustomQueue, useQueue } from "@uidotdev/usehooks";
import { useLatest } from "react-use";

type speechStatusType = 'loading' | 'speeching' | 'stop';
type speechQueueType = { id: number, content: string };
type blobDictType = { [key: string]: string };

const useSpeechState = create<{
  voiceId: string;
  speechStatus: speechStatusType;
  getVoiceId: () => string;
  setVoiceId: (s: string) => void;
  getSpeechStatus: () => speechStatusType;
  setSpeechStatus: (b: speechStatusType) => void;
}>((set, get) => {
  return {
    voiceId: "Takumi",
    getVoiceId: () => { return get().voiceId },
    setVoiceId: (s: string) => {
      set(() => ({
        voiceId: s,
      }));
    },
    speechStatus: 'stop',
    getSpeechStatus: () => { return get().speechStatus },
    setSpeechStatus: (b: speechStatusType) => {
      set(() => ({
        speechStatus: b,
      }));
    }
  };
});

const useSpeech = () => {
  const {
    speechStatus,
    setSpeechStatus,
    getSpeechStatus,
    getVoiceId,
    setVoiceId,
  } = useSpeechState();
  const { getSpeech } = useBot();
  const { play, stop } = useDynamicAudio();
  const speechQueue = useQueue<speechQueueType>([]);
  const speechQueueLatest = useLatest<CustomQueue<speechQueueType>>(speechQueue);
  const [blobDict, setBlobDict] = useState<blobDictType>({});
  const blobDictLatest = useLatest<blobDictType>(blobDict);

  const onPlaySpeech = async (content: string) => {
    setSpeechStatus('loading');
    const bloburl = URL.createObjectURL(await getSpeech(content, getVoiceId()))
    play(bloburl, 'mp3', () => { setSpeechStatus('stop'); })
    setSpeechStatus('speeching');
  };
  const onStopSpeech = () => {
    stop();
    speechQueueLatest.current.clear();
    setSpeechStatus('stop');
  }

  const onPlaySpeechStreaming = async (add_content: string) => {
    console.debug("speech add " + add_content)
    // Queueに加えるだけ
    const id = Math.floor(Math.random() * 999999999999999);
    speechQueue.add({ id: id, content: add_content });
    let response = null, count = 0;
    while (response == null) {
      response = await getSpeech(add_content, getVoiceId()).catch(() => null);
      count = count + 1;
      if (count > 10) {
        throw new Error("Error to Synchronize");
      }
    }
    const bloburl = URL.createObjectURL(response);
    setBlobDict((state) => { state[id] = bloburl; return state; })
    console.debug("Synchronized:" + add_content);
  };

  const playNext = useCallback(() => {
    (async () => {
      if (speechQueueLatest.current.first === undefined) {
        console.debug("stop");
        setSpeechStatus("stop");
        return;
      }
      const id = speechQueueLatest.current.first.id;
      if (id in blobDictLatest.current) {
        setSpeechStatus("speeching");
        const bloburl = blobDictLatest.current[id]
        console.debug("speech pop " + speechQueueLatest.current.first.content)
        speechQueueLatest.current.remove();
        play(bloburl, 'mp3', () => {
          console.debug("next");
          playNext();
        }/*, 1, 0*/)
      } else {
        console.debug("wait");
        setTimeout(playNext, 100);
      }
    })();
  }, [blobDictLatest, play, setSpeechStatus, speechQueueLatest]);

  useEffect(() => {
    if (getSpeechStatus() == 'stop' && speechQueue.size > 0) {
      console.debug("start");
      setSpeechStatus("loading");
      playNext();
    }
  }, [playNext, getSpeechStatus, speechQueue.size, setSpeechStatus]);


  return {
    speechStatus,
    onPlaySpeech,
    onPlaySpeechStreaming,
    onStopSpeech,
    playNext,
    setVoiceId,
  };
};

export default useSpeech;
