import React, { useEffect, useState } from 'react';
import { BaseProps } from '../@types/common';
import { useFaceRecognition } from '../hooks/useFaceRecognition';
import ButtonIcon from './ButtonIcon';
import { LuScanFace } from 'react-icons/lu';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

type Props = BaseProps & {
  size?: number
};

const FaceExpressions: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { startCam, stopCam, isReady, isStarted, videoRef } = useFaceRecognition();
  const [isFaceExpressions, setFaceExpressions] = useState<boolean>(false);

  useEffect(() => {
    // カメラを起動
    if (isFaceExpressions && !isStarted) {
      startCam();
    }

    if (!isFaceExpressions && isReady) {
      stopCam();
    }
  }, [isFaceExpressions, isReady, isStarted, startCam, stopCam]);
  useEffect(() => {
    // アンマウント時にstopCamを実行
    return () => { stopCam(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ButtonIcon className='text-end' onClick={() => { setFaceExpressions(!isFaceExpressions) }}>
        <Tooltip id="faceRecognitionTooltip" />
        <div
          data-tooltip-id="faceRecognitionTooltip"
          data-tooltip-content={t('app.faceExpression.aggreement.title')}>
          {isFaceExpressions ? (
            <div className="bg-aws-font-color rounded p-1 m-[-0.25rem]">
              <LuScanFace className='text-white' size={props.size} />
            </div>
          ) : (
              <LuScanFace size={props.size} />
          )}
        </div>
      </ButtonIcon>
      <video ref={videoRef} className='hidden' ></video>
    </>
  );
};

export default FaceExpressions;