import React, { useEffect, useState } from 'react';
import { BaseProps } from '../@types/common';
import { twMerge } from 'tailwind-merge';
import useEzAuthApi from '../hooks/useEzAuthApi';

type Props = BaseProps & {
  title: string;
  children: React.ReactNode;
};

const Collapse: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>(props.title);
  const { getUserName } = useEzAuthApi();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    (async () => {
      setTitle((await getUserName({ userId: props.title, ezAuthId: "" })).data.userName)

    })()
  }, [props.title, getUserName])

  return (
    <div className={twMerge("max-h-[50vh] py-3", props.className)}>
      <button
        onClick={toggle}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
      >
        {title}
      </button>
      {isOpen && (
        <div className="border border-gray-300 rounded overflow-y-auto max-h-full">
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Collapse;
