import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ChatPage from './pages/ChatPage.tsx';
import NotFound from './pages/NotFound.tsx';
import './i18n';
import BotExplorePage from './pages/BotExplorePage.tsx';
import BotEditPage from './pages/BotEditPage.tsx';
import BotApiSettingsPage from './pages/BotApiSettingsPage.tsx';
import AdminSharedBotAnalyticsPage from './pages/AdminSharedBotAnalyticsPage.tsx';
import AdminApiManagementPage from './pages/AdminApiManagementPage.tsx';
import AdminBotManagementPage from './pages/AdminBotManagementPage.tsx';
import EzAuthPage from './pages/EzAuthPage.tsx';
import { Amplify } from 'aws-amplify';
import EzAuthAdminPage from './pages/EzAuthAdminPage.tsx';
import EzAuthGroupAdminPage from './pages/EzAuthGroupAdminPage.tsx';
import FederateAuthenticatePage from './pages/FederateAuthenticatePage.tsx';
import FederateAuthenticatedPage from './pages/FederateAuthenticatedPage.tsx';


Amplify.configure({
  Auth: {
    userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: import.meta.env.VITE_APP_COGNITO_DOMAIN,
      scope: ['openid', 'email'],
      redirectSignIn: import.meta.env.VITE_APP_REDIRECT_SIGNIN_URL,
      redirectSignOut: import.meta.env.VITE_APP_REDIRECT_SIGNOUT_URL,
      responseType: 'code',
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/federate/authenticate',
    element: <FederateAuthenticatePage />,
  },
  {
    path: '/federate/authenticated',
    element: <FederateAuthenticatedPage />,
  },
  {
    path: '/ez_auth/:userId/:ezAuthId',
    element: <EzAuthPage />,
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <ChatPage />,
      },
      {
        path: '/bot/explore',
        element: <BotExplorePage />,
      },
      {
        path: '/bot/new',
        element: <BotEditPage />,
      },
      {
        path: '/bot/edit/:botId',
        element: <BotEditPage />,
      },
      {
        path: '/bot/api-settings/:botId',
        element: <BotApiSettingsPage />,
      },
      {
        path: '/bot/:botId',
        element: <ChatPage />,
      },
      {
        path: '/admin/shared-bot-analytics',
        element: <AdminSharedBotAnalyticsPage />,
      },
      {
        path: '/admin/api-management',
        element: <AdminApiManagementPage />,
      },
      {
        path: '/admin/bot/:botId',
        element: <AdminBotManagementPage />,
      },
      {
        path: '/:conversationId',
        element: <ChatPage />,
      },
      {
        path: '/ez_auth/admin',
        element: <EzAuthAdminPage />,
      },
      {
        path: '/ez_auth/group-admin',
        element: <EzAuthGroupAdminPage />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
