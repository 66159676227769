import { ReactNode } from 'react';
import { BaseProps } from '../@types/common';
import { useTranslation } from 'react-i18next';
import { EzAuthLink } from '../@types/ez-auth';
import { formatDatetime } from '../utils/DateUtils';

type Props = BaseProps & {
  link: EzAuthLink;
  onClick: (link: EzAuthLink) => void;
  children: ReactNode;
};

const ListItemLink: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const myLink = props.link;
  return (
    <div
      key={myLink.id}
      className={`${props.className ?? ''
        } relative flex w-full justify-between border-b border-light-gray`}>
      <div
        className='h-full grow bg-aws-paper p-2 cursor-pointer hover:brightness-90'
        onClick={() => { props.onClick(myLink) }}>
        <div className="w-full overflow-hidden text-ellipsis text-sm font-semibold">
          {t("ezAuth.admin.label.createDate")}:{formatDatetime(new Date(myLink.createTimestamp * 1000))}
        </div>

        <div className="mt-1 overflow-hidden text-ellipsis text-xs">
          {myLink.disabled ? t("ezAuth.admin.label.disabled") : t("ezAuth.admin.label.enabled")}
        </div>
      </div>

      <div className="absolute right-0 flex h-full justify-between ">
        <div className="w-10 bg-gradient-to-r from-transparent to-aws-paper"></div>
        <div className="flex items-center  gap-2 bg-aws-paper pl-2">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ListItemLink;
